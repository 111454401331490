import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import './App.css';

function App() {
    const containerStyle = {
        maxWidth: '600px',
        margin: '40px auto',
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        borderRadius: '8px'
    };

    const linkStyle = {
        display: 'inline-block',
        marginTop: '20px',
        color: '#000',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
    };

    return (
        <div style={containerStyle}>
            <h1>Ruderknaegt</h1>
            
            <div>
                <p>Cleaning Service</p>
                <p>♦️ Traditionel vinduespolering</p>
                <p>🦢 Svanemærket produkter</p>
                <p>💎 Krystalklart Resultat</p>

                <h4>Contact Information:</h4>
                <p>Owned by: Andreas Peter Bøgebo</p>
            </div>

            <a href="https://www.instagram.com/ruderknaegt/" target="_blank" rel="noreferrer" style={linkStyle}>
                <FaInstagram size="32" />
            </a>
        </div>
    );
}

export default App;
